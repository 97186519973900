
export enum VendorUserRoles {
	Admin = "94d00b8a-c3a3-439c-bd76-4df524d52bd9",
	DocumentAcquisitionSpecialist = "fa60110b-bfc6-49b0-b838-5ac2a4cedd84",
	Developer = "61fd5e50-f189-4d5f-bdca-c70e6ba1eb70",
	SalesTeam = "00000000-0000-0000-0040-000000000000",
	RelationshipManager = "a2af23fd-88b8-45d3-ac3b-ab8893971ab3",
	RelationshipSpecialist = "8ceccac2-810e-4f2a-8c48-45f2be9cd37f",
	CISSP = "79759a9a-e62e-4b37-aaa9-c344ea5ae9ce",
	PKM = "1fc612a4-866b-4ed4-aac8-66bf40cbed80",
	VendorSuccess = "59588232-0f56-4ad2-8739-4a847d27d1e5",
	Contracting = "35a1e99f-7884-4138-a28d-f4bbc4fe5222",
	CPA = "40650f7f-cbd8-4c4a-9b95-b925133ca284",
	Paralegal = "2b91f49c-3fb4-40bc-8df2-855f78fcfc2f",
	Implementation = "b1c81d1d-e4fe-4dd3-8998-af6926f32060",
	DocumentManager = "890cc3a0-52c9-4324-be79-8f5f2583abb3",
	Manager = "bf0b9c3c-e0df-43d0-a142-5e28f216917f",
	OverrideFirstContractData = "9aa25ae8-a6b1-458c-a414-06597e0e70dd",
	VendorQuestionnairePOC = "62f76e4b-10ec-40eb-8f85-d6975cca692e",
	CanReassignOrderVerification = "16b8f443-eca2-458f-83b0-8a08b14fbf1f",
	CanCompleteFinancials = "5abd6bdd-3615-412c-adbf-ed1e60413fad",
	CanPublishAssessments = "5181d443-baeb-45c1-9f1f-37756b38cc96",
	CanManageVenminderExchangeStatuses = "46a44595-cf44-427a-8c6d-edb80171766a",
	DownloadAllContracts = "3edf9555-0c68-443c-9277-6e3c5a7de8a0",
	DCAdmin_Area_ArgosDataFeed = "00000000-0000-0000-0060-000000000000",
	DCAdmin_Area_Billing = "00000000-0000-0000-0002-000000000000",
	DCAdmin_Area_ChannelSupport = "00000000-0000-0000-0003-000000000000",
	DCAdmin_Area_ClientContractUploadQueue = "00000000-0000-0000-0004-000000000000",
	DCAdmin_Area_ClientSupport = "00000000-0000-0000-0005-000000000000",
	DCAdmin_Area_ContentReferenceGuide = "00000000-0000-0000-000e-000000000000",
	DCAdmin_Area_ControlAssessments = "14c779c0-a204-4faa-900d-2a81d07b5873",
	DCAdmin_Area_DCUsers = "00000000-0000-0000-0050-000000000000",
	DCAdmin_Area_DocumentCollection = "00000000-0000-0000-0006-000000000000",
	DCAdmin_Area_DocumentCollectionSpecialist = "00000000-0000-0000-0007-000000000000",
	DCAdmin_Area_DocumentCollectionVendorSupport = "00000000-0000-0000-0020-000000000000",
	DCAdmin_Area_DownloadAll = "00000000-0000-0000-0010-000000000000",
	DCAdmin_Area_Email = "00000000-0000-0000-000f-000000000000",
	DCAdmin_Area_ExecutiveLevelAnalysis = "00000000-0000-0000-0008-000000000000",
	DCAdmin_Area_FulfillmentVendorMatch = "00000000-0000-0000-0090-000000000000",
	DCAdmin_Area_Implementations = "00000000-0000-0000-0030-000000000000",
	DCAdmin_Area_ImplementationsSpecialist = "00000000-0000-0000-0009-000000000000",
	DCAdmin_Area_Reports = "00000000-0000-0000-000b-000000000000",
	DCAdmin_Area_SalesAndContracting = "00000000-0000-0000-000c-000000000000",
	DCAdmin_Area_SalesCancellation = "00000000-0000-0000-0070-000000000000",
	DCAdmin_Area_ClinicInvitations = "00000000-0000-0000-0080-000000000000",
	DCAdmin_Area_CISSP = "79759a9a-e62e-4b37-aaa9-c344ea5ae9ce",
	DCAdmin_Area_Vendor_Success = "59588232-0f56-4ad2-8739-4a847d27d1e5",
	DCAdmin_Area_PKM = "1fc612a4-866b-4ed4-aac8-66bf40cbed80",
	DCAdmin_Area_ServicesVerification = "3f958f64-f546-4371-ba30-f98be7b69b9f",
	DCAdmin_Area_Whitelisting = "4311eea5-78ff-4400-9436-b862a0b1a840",
	DCAdmin_Area_FeatureManagement = "b143a5c7-2526-422d-9414-5149c7e85501",
	DCAdmin_Area_GlobalVariables = "4d51fcbf-bdd8-4d38-9d12-8abede186380",
};

