import { Aurelia, Container, inject } from 'aurelia-framework';
import { NavigationInstruction, Next, Redirect, Router } from 'aurelia-router';
import type { IAppSettingsService } from '../../../venminder-default/shared/interfaces/IAppSettingsService';
import type { IAuthService } from '../interfaces/IAuthService';
import type { IUserService } from '../interfaces/IUserService';
import { PublicAppSettings } from '../models/app-settings';
import { LoggedInUser } from '../models/userInfo';
import { AppSettingsService } from '../services/appSettingsService';
import { AuthService } from '../services/authService';
import { UserService } from '../services/userService';
import { isNullOrUndefined } from '../utilities/globals';

export class AuthorizeStep {
    public userInfo: LoggedInUser;
    public userIsLoggedIn: boolean = false;
    public roleAuthorizers: Map<string, IRoleAuthorizer> = new Map<string, IRoleAuthorizer>();

    //private userRoles: string[] = null;

    constructor(
        @inject(Aurelia) public aurelia: Aurelia,
        @inject(Router) private router: Router,
        @inject(AuthService) private authService: IAuthService,
        @inject(UserService) private userService: IUserService,
        @inject(AppSettingsService) private appSettings: IAppSettingsService,
    ) {
        this.roleAuthorizers.set('DC', new VmAdminRoleAuthorizer(aurelia.container, router));
    }

    public async run(navigationInstruction: NavigationInstruction, next: Next): Promise<any> {
        let isRouteAccessable = true;

        const appSettings = await this.appSettings.getAppSettings();

        const allInstructions = navigationInstruction.getAllInstructions();
        const routeRequiresUserBeLoggedIn = allInstructions.some((i) => i.config.settings.auth);
        const routeRequiresUserBeLoggedOut = allInstructions.some((i) => i.config.settings.unAuth);

        this.userIsLoggedIn = await this.authService.isLoggedIn();

        if (this.userIsLoggedIn) {
            if (routeRequiresUserBeLoggedOut) {
                return next.cancel(new Redirect(appSettings.rsdUrl + `/user/logout`));
            } else {
                isRouteAccessable = await this.checkIfRouteIsAccessibleForUser(allInstructions, navigationInstruction, appSettings);
            }
        } else if (routeRequiresUserBeLoggedIn) {
            this.authService.login();
            return next.complete();
        }

        if (isRouteAccessable) {
            return next();
        } else {
            return next.cancel(new Redirect(appSettings.rsdUrl + '/user/dashboard'));
        }
    }

    private async checkIfRouteIsAccessibleForUser(
        allInstructions: NavigationInstruction[],
        navigationInstruction: NavigationInstruction,
        appSettings: PublicAppSettings,
    ) {
        const user = await this.userService.getUserInfo();

        // if (!this.userRoles) {
        //   const apiClient = new AuthApiClient(appSettings.authApiUrl, this.userService, this.authService);
        //   this.userRoles = await apiClient.getCurrentUserRoles();
        // }

        let isRouteAccessable = true; //this.checkRoles(allInstructions, this.userRoles);

        if (isRouteAccessable) {
            for (let i = 0; i < user.roles.length; i++) {
                const role = user.roles[i];
                if (this.roleAuthorizers.has(role.name)) {
                    isRouteAccessable = await this.roleAuthorizers
                        .get(role.name)
                        .checkIsRouteAccessible(allInstructions, navigationInstruction, user);
                    if (isRouteAccessable) {
                        return true;
                    }
                }
            }
        }

        return isRouteAccessable;
    }

    // public checkRoles(allInstructions: NavigationInstruction[], userRoles: string[]): boolean {
    //   let roles = new Set<string>();
    //   allInstructions.forEach(navigationInstruction => {
    //     if (navigationInstruction.config
    //       && navigationInstruction.config.settings
    //       && navigationInstruction.config.settings.roles
    //       && navigationInstruction.config.settings.roles.length > 0) {
    //       navigationInstruction.config.settings.roles.forEach(role => {
    //         if (!roles.has(role))
    //           roles.add(role);
    //       });
    //     }
    //   })

    //   let hasAccess = roles.size == 0;
    //   roles.forEach(role => {
    //     if (userRoles.includes(role))
    //       hasAccess = true;
    //   });

    //   return hasAccess;
    // }
}

export class VmAdminRoleAuthorizer implements IRoleAuthorizer {
    constructor(private diContainer: Container, private router: Router) {}

    public async checkIsRouteAccessible(
        allInstructions: NavigationInstruction[],
        navigationInstruction: NavigationInstruction,
        user: LoggedInUser,
    ): Promise<boolean> {
        let venminderUserRoles: Map<string, boolean> = new Map<string, boolean>();
        allInstructions.forEach((i) => {
            const vmRoles: string[] = i?.config?.settings?.venminderRoles ?? [];
            vmRoles.forEach((r) => {
                if (!venminderUserRoles.has(r)) {
                    venminderUserRoles.set(r, true);
                }
            });
        });
        const uniqueRolesRequiredForRouteAccess = Array.from(venminderUserRoles.keys());
        const firstRequiredRoleTheUserDoesNotHave = uniqueRolesRequiredForRouteAccess.find((r) =>
            isNullOrUndefined(user.venminderRoles.find((role) => role.roleID == r || role.name == r)),
        );
        return isNullOrUndefined(firstRequiredRoleTheUserDoesNotHave);
    }
}

export interface IRoleAuthorizer {
    checkIsRouteAccessible(
        allInstructions: NavigationInstruction[],
        navigationInstruction: NavigationInstruction,
        user: LoggedInUser,
    ): Promise<boolean>;
}
