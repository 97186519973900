import { inject } from "aurelia-dependency-injection";
import { HttpClient } from "aurelia-fetch-client";
import { AuthService } from "./authService";
import { Aurelia } from "aurelia-framework";

export class HttpAuthClient extends HttpClient {
  public httpConfigured = false;

  constructor(
    @inject(Aurelia) private aurelia: Aurelia
  ) {
    super();
  }

  addAuth = async (): Promise<HttpAuthClient> => {
    // There's a risk of a circular dependency problem here, so only getting the 
    // AuthService after construction and only when needed. Not a good solution.
    var authService = this.aurelia.container.get(AuthService);
    const user = await authService.getUser();
    this.setConfiguration("Bearer", user.access_token);
    return this;
  }

  removeAuth = async (): Promise<HttpAuthClient> => {
    if (this.httpConfigured) {
      this.unsetConfiguration();
    }
    return this;
  }

  private setConfiguration = (tokenType: string, accessToken: string): void => {
    this.configure(config => {
      config
        .useStandardConfiguration()
        .withInterceptor({
          request(request) {
            request.headers.delete("Authorization");
            request.headers.append("Authorization", `${tokenType} ${accessToken}`);
            return request;
          }
        });
    });
    this.httpConfigured = true;
  }

  private unsetConfiguration = (): void => {
    this.configure(config => {
      config.withDefaults({
        credentials: 'include',
        headers: { 'X-Requested-With': 'Fetch' }
      });
    });
    this.httpConfigured = false;
  }
}
