import { inject } from 'aurelia-framework';
import type { IApiService } from 'shared/interfaces/IApiService';
import { GetApiRequest, GetJsonRequest } from 'shared/models/api-service/api-service-model';
import { ApiService } from 'shared/services/apiService';
import { ApiUrlService } from 'venminder-default/shared/services/apiUrlService';
import { AppSettingsService } from 'venminder-default/shared/services/appSettingsService';
import { AuthService } from 'venminder-default/shared/services/authService';
import { DataCacheService } from 'venminder-default/shared/services/data-cache-service';
import { ContentType } from '../content-type';
import { Endpoints } from '../endpoints';
import type { IApiUrlService } from '../interfaces/IApiUrlService';
import type { IAppSettingsService } from '../interfaces/IAppSettingsService';
import type { IAuthService } from '../interfaces/IAuthService';
import type { IUserService } from '../interfaces/IUserService';
import type { IDataCache } from '../interfaces/data-cache-interface';
import { DataCacheOptions } from '../interfaces/data-cache-interface';
import { FIRoleForDisplayModel, PhoneTypeModel } from '../models/add-user/add-user-model';
import { FIContact } from '../models/contact';
import { LoggedInUser, UserRole } from '../models/userInfo';
import { ViewModelResponse } from '../models/viewModelResponse';
import { Guid } from '../utilities/guid';

export class UserService implements IUserService {
    private static getUserInfoCacheKey: string = Guid.newGuid().format('N');
    private static getUserRolesCacheKey: string = Guid.newGuid().format('N');
    private static getUnreadInboxMessageCountCacheKey: string = Guid.newGuid().format('N');

    constructor(
        @inject(ApiService) private api: IApiService,
        @inject(ApiUrlService) private apiUrl: IApiUrlService,
        @inject(AppSettingsService) private appSettingsService: IAppSettingsService,
        @inject(DataCacheService) private dataCache: IDataCache,
        @inject(AuthService) private authService: IAuthService,
    ) {}

    clearUserInfo(): void {
        this.dataCache.delete(UserService.getUserInfoCacheKey);
        this.dataCache.delete(UserService.getUserRolesCacheKey);
    }

    async getUserInfo(forceRefresh?: boolean): Promise<LoggedInUser> {
        if (forceRefresh === true) this.dataCache.delete(UserService.getUserInfoCacheKey);

        return await this.dataCache.get(
            UserService.getUserInfoCacheKey,
            async () => {
                let result = await this.api.getJson(new GetJsonRequest(Endpoints.Api.Global.Users.GET_USER_INFO, undefined, undefined, true));
                return new LoggedInUser(result.value);
            },
            DataCacheOptions.NeverExpires,
        );
    }

    isFeatureOnForUser(featureID: number): Promise<boolean> {
        return this.dataCache.get(
            `isFeatureOnForUser_${featureID}`,
            () => {
                return this.api
                    .getJson<boolean>(new GetJsonRequest(Endpoints.Api.Global.Users.IS_FEATURE_ON_FOR_USER, `featureId=${featureID}`, undefined, true))
                    .then((u) => u);
            },
            DataCacheOptions.Default,
        );
    }

    async areFeaturesOnForUser(featureIDs: number[]): Promise<Map<number, boolean>> {
        var qp = '';
        featureIDs.forEach((featureId) => {
            if (qp != '') {
                qp += '&';
            }
            qp += `featureIds=${featureId}`;
        });

        let res = await this.api.getJson<boolean[]>(new GetJsonRequest(Endpoints.Api.Global.Users.ARE_FEATURES_ON_FOR_USER, qp, undefined, true));
        let map = new Map<number, boolean>();
        for (let i in res) {
            let featureId = parseInt(i);
            if (!isNaN(featureId)) {
                map.set(featureId, res[i]);
            }
        }

        return map;
    }

    
}
