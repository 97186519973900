import type { IAuthService } from '../interfaces/IAuthService';
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { PublicAppSettings } from '../models/app-settings';
import { AppSettingsService } from './appSettingsService';
import type { IAppSettingsService } from '../interfaces/IAppSettingsService';
import { Aurelia, inject } from 'aurelia-framework';
import _ from 'lodash';
import { hasValue } from '../utilities/globals';


export class AuthService implements IAuthService {
  private userManager: UserManager = null;
  private appSettings: PublicAppSettings;
  private stateStore = window.sessionStorage;

  public readonly oidcSpaClientName = "venminderFlexBillingSpaApp";
  private readonly scopes = new Array(
    "venminderFlexBillingApi",
    "offline_access"
  );

  constructor(@inject(Aurelia) private aurelia: Aurelia
  ) {
  }

  // simple check of logged in status: if there is a token, we're (probably) logged in.
  // ideally we check status and check token has not expired (server will back us up, if this not done, but it could be cleaner)
  public async isLoggedIn(): Promise<boolean> {
    const user = await this.getUser();
    return hasValue(user?.access_token);
  }

  public async getUser(): Promise<User> {
    let user = await (await this.getUserManager()).getUser();

    if(user && (!user.access_token || user.expired)) {
      user = await this.renewToken();
    }

    return user;
  }

  private hasLoggedIn: boolean = false;
  public async login(): Promise<void> {
    this.hasLoggedIn = true;
    await (await this.getUserManager()).signinRedirect();
  }

  public async renewToken(): Promise<User> {
    return (await this.getUserManager()).signinSilent();
  }

  public async logout(): Promise<void> {
    await (await this.getUserManager()).signoutRedirect();
  }

  public async signinCallback(): Promise<void | User> {
    return (await this.getUserManager()).signinCallback();
  }

  public async refreshCallback(): Promise<void> {
    return (await this.getUserManager()).signinSilentCallback();
  }

  public async clearSession(): Promise<void> {
    await this.removeOidcStateStorageKeys();
    await (await this.getUserManager()).clearStaleState();
  }

  private async getUserManager(): Promise<UserManager> {
    if (this.userManager == null) {
      const appSettings = await this.getAppSettings();
      const oidcSettings = this.getOidcClientSettings(appSettings);
      this.userManager = new UserManager(oidcSettings);
    }
    return this.userManager;
  }

  private getOidcClientSettings(appSettings: PublicAppSettings): UserManagerSettings {
    if (!appSettings) return null;

    return {
      client_id: this.oidcSpaClientName,
      authority: appSettings.authUrl,
      redirect_uri: `${appSettings.flexBillingUrl}user/auth/oidc/signin-redirect`,
      silent_redirect_uri: `${appSettings.flexBillingUrl}user/auth/oidc/refresh-redirect`,
      response_type: "code",
      scope: this.scopes.join(" "),
      userStore: new WebStorageStateStore({ store: this.stateStore }),
    };
  }

  private async getAppSettings(): Promise<PublicAppSettings> {
    if (this.appSettings) { return this.appSettings; }
    const appSettingsService = this.aurelia.container.get(AppSettingsService);
    this.appSettings = await appSettingsService.getAppSettings();
    return this.appSettings;
  }

  private async removeOidcStateStorageKeys(): Promise<void> {
    const self = this;
    const keys = await self.getOidcStateStorageKeys();

    if (keys && keys.length > 0) {
      _.forEach(keys, (item) => self.stateStore.removeItem(item));
    }
  }

  private getOidcStateStorageKeys() {
    const self = this;
    const keys = Object.keys(this.stateStore);
    if (keys && keys.length > 0) {
      return _.filter(keys, (key) => key.indexOf(this.oidcSpaClientName) >= 0);
    }
    return null;
  }
}
