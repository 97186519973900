
export enum Feature {
	contractPackageDownload = 1,
	taskAndNotificationsWidgetReassignAssessmentTask = 2,
	visibleAPIKeys = 4,
	regenerateRiskAssessmentPDF = 8,
	editCompletedRiskAssessmentComments = 9,
	businessUnits = 10,
	workflows = 11,
	executiveDashboard = 12,
	procBasedDocTagsForVendors = 14,
	procBasedDocTagsForVendorsCaching = 15,
	newGetLatestContractsAndProductsLogic = 16,
	disableProductTypeAutocomplete = 17,
	quickDeliveryLibrary = 18,
	disableBusinessUnitWizard = 19,
	enableBusinessUnitWizardOverride = 20,
	argosIntegration = 22,
	disablePendoCookies = 23,
	enablePendoCookies = 24,
	oversightDashboard = 25,
	oversightAutomationByInherentRiskQuestions = 26,
	questionnaireRefreshFunctionalitySendLatest = 28,
	questionnaireEnhancements = 29,
	vendorProfileSync = 100,
	internalVendorProfileSync = 101,
	profileSyncChangeTracking = 102,
	aPIISPAControlAssessments = 256,
	bitSightInOnboarding = 300,
	taskApprovals = 555,
	getOnboardingRequests = 613,
	getOnboardingRequestDetails = 614,
	getOnboardingInformation = 615,
	getOnboardingFormDetails = 616,
	saveOnboardingRequest = 617,
	getOnboardingFormAnswers = 618,
	getQuestionnairesResponses = 619,
	getQuestionnaireLibrary = 620,
	downloadQuestionareZip = 621,
	contractDataFieldDateReminder = 1337,
	questionnaireQuestionsDisplaySerialNumbers = 5150,
	downloadCuec = 32153,
	newVenminderAdminDashboard = 142214,
	loadVendorMonitoringInNewTab = 234234,
	clientSelfProcessedContracts = 319163,
	clientNoVenminderProcessedContracts = 319164,
	editVendorServicesOnly = 320329,
	prevailingScoreConfiguration = 896656,
	vendorDashboardDocumentStorage = 984166,
	vendorOnboardingDashboardAdvancedFilters = 987654,
	vendorOnboardingDashboardNewRequestsView = 987655,
	vendorOnboardingDashboardQuickView = 987656,
	clakPdfStandard = 5872348,
	clakPdfEnterprise = 5872349,
	questionnaireQuestionAttachSerialNumbers = 5882300,
	questionnaireAutoSave = 8675309,
	productProfileByCategory = 8759230,
	exchangeRequestAccess = 10736673,
	criticalityAssessment = 10956586,
	buAccordionExperience = 20220407,
	clientLogoInQuestionnaireEmail = 43598237,
	vendorDashboardAssessment = 53070271,
	venminderExchangeAllInvite = 81721663,
	reportQueueing = 158855603,
	vendorSnapshotSoftRelease = 430425100,
	vendorSnapshotFullRelease = 430425101,
	vendorLifecycleExperience = 628318525,
	vendorLifecycleExperienceV2 = 628318526,
	vendorOffboardingModule = 628318530,
	vendorLifecycleModule = 628318531,
	vendorLifecycleOngoingView = 628318532,
	vendorOffboardingDashboardQuickView = 628318533,
	exchangeShareHistory = 727455896,
	salesNewVendorSale = 752252843,
	questionnairesProfileSync = 1072219065,
	exchangeUserManagement = 1094027784,
	exchangeVendorAnswerLibrary = 1213395587,
	vendorDashboardQuestionnaire = 1237937652,
	exchangeVendorDocuments = 1349716721,
	defaultHomePage = 1350231787,
	exchangeCompanyManagement = 1583882683,
	exchangeVendorBulkUpload = 1655318234,
	enableBusinessUnitNewExperience = 1805620198,
	exchangeVendorProductsQuestionnairesShare = 2074550539,
	contractsWidgetDownloadAllButton = 2074550540,
	globalToDo = 2080818140,
};

