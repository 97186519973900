import { inject } from 'aurelia-framework';
import type { IApiService } from 'shared/interfaces/IApiService';
import { GetJsonRequest } from 'shared/models/api-service/api-service-model';
import { ApiService } from 'shared/services/apiService';
import type { IDataCache } from 'venminder-default/shared/interfaces/data-cache-interface';
import { DataCacheOptions } from 'venminder-default/shared/interfaces/data-cache-interface';
import { ApiUrlService } from 'venminder-default/shared/services/apiUrlService';
import { DataCacheService } from 'venminder-default/shared/services/data-cache-service';
import { Guid } from 'venminder-default/shared/utilities/guid';
import * as appsettings from '../../../../config/appsettings.json';
import { Endpoints } from '../endpoints';
import type { IApiUrlService } from '../interfaces/IApiUrlService';
import type { IAppSettingsService } from '../interfaces/IAppSettingsService';
import { LayoutInformation, PublicAppSettings } from '../models/app-settings';
import { ViewModelResponse } from '../models/viewModelResponse';

export class AppSettingsService implements IAppSettingsService {
    private static getAppSettingsCacheKey: string = Guid.newGuid().format('N');
    private static getLayoutInformationCacheKey: string = Guid.newGuid().format('N');

    constructor(
        @inject(ApiService) private api: IApiService,
        @inject(ApiUrlService) private apiUrl: IApiUrlService,
        @inject(DataCacheService) private dataCache: IDataCache,
    ) {}

    async getAppSettings(): Promise<PublicAppSettings> {
        let env = this.determineEnv(document.URL.toString());

        let settings = await this.dataCache.get(
            AppSettingsService.getAppSettingsCacheKey,
            async () => {
                //let result = await this.api.getJson(new GetJsonRequest(Endpoints.Api.Global.GET_APP_SETTINGS));

                let settings = PublicAppSettings.create();
                switch (env) {
                    case 'DEV': {
                        //statements;

                        settings.authUrl = this.fixURL(appsettings.AuthUrl_Development);
                        settings.appUrl = this.fixURL(appsettings.AppUrl_Development);
                        settings.rsdUrl = this.fixURL(appsettings.RsdUrl_Development);
                        settings.flexBillingCustomersApiClientUrl = appsettings.Billing_Customers_Api_Development;
                        settings.flexBillingEventsApiClientUrl = appsettings.Billing_Customers_Api_Development;
                        settings.authApiUrl = appsettings.Auth_Api;
                        settings.billingArchiveApiUrl = this.fixURL(appsettings.Billing_Archive_Api);
                        settings.flexBillingUrl = this.fixURL(appsettings.Flex_Billing_URL_Development);

                        this.apiUrl.setDefaultRemoteBaseUrl(settings.appUrl);

                        let elm = <HTMLLinkElement>document.getElementById('DistFolder');
                        settings.distFolder = elm?.href;
                        break;
                    }
                    case 'LOCAL': {
                        //statements;

                        settings.authUrl = this.fixURL(appsettings.AuthUrl_Development);
                        settings.appUrl = this.fixURL(appsettings.AppUrl_Development);
                        settings.rsdUrl = this.fixURL(appsettings.RsdUrl_Development);
                        settings.flexBillingCustomersApiClientUrl = appsettings.Billing_Customers_Api_Development;
                        settings.flexBillingEventsApiClientUrl = appsettings.Billing_Customers_Api_Development;
                        settings.authApiUrl = appsettings.Auth_Api;
                        settings.billingArchiveApiUrl = this.fixURL(appsettings.Billing_Archive_Api);
                        settings.flexBillingUrl = this.fixURL(appsettings.Flex_Billing_URL_Local);

                        this.apiUrl.setDefaultRemoteBaseUrl(settings.appUrl);

                        let elm = <HTMLLinkElement>document.getElementById('DistFolder');
                        settings.distFolder = elm?.href;
                        break;
                    }
                    case 'QA': {
                        //statements;

                        settings.authUrl = this.fixURL(appsettings.AuthUrl_QA);
                        settings.appUrl = this.fixURL(appsettings.AppUrl_QA);
                        settings.rsdUrl = this.fixURL(appsettings.RsdUrl_QA);
                        settings.flexBillingCustomersApiClientUrl = appsettings.Billing_Customers_Api_QA;
                        settings.flexBillingEventsApiClientUrl = appsettings.Billing_Customers_Api_QA;
                        settings.authApiUrl = appsettings.Auth_Api;
                        settings.billingArchiveApiUrl = this.fixURL(appsettings.Billing_Archive_Api);
                        settings.flexBillingUrl = this.fixURL(appsettings.Flex_Billing_URL_QA);

                        this.apiUrl.setDefaultRemoteBaseUrl(settings.appUrl);

                        let elm = <HTMLLinkElement>document.getElementById('DistFolder');
                        settings.distFolder = elm?.href;
                        break;
                    }
                    case 'PROD': {
                        //statements;

                        settings.authUrl = this.fixURL(appsettings.AuthUrl);
                        settings.appUrl = this.fixURL(appsettings.AppUrl);
                        settings.rsdUrl = this.fixURL(appsettings.RsdUrl);
                        settings.flexBillingCustomersApiClientUrl = appsettings.Billing_Customers_Api;
                        settings.flexBillingEventsApiClientUrl = appsettings.Billing_Customers_Api;
                        settings.authApiUrl = appsettings.Auth_Api;
                        settings.billingArchiveApiUrl = this.fixURL(appsettings.Billing_Archive_Api);
                        settings.flexBillingUrl = this.fixURL(appsettings.Flex_Billing_URL);

                        this.apiUrl.setDefaultRemoteBaseUrl(settings.appUrl);

                        let elm = <HTMLLinkElement>document.getElementById('DistFolder');
                        settings.distFolder = elm?.href;
                        break;
                    }
                }
                return settings;
            },
            DataCacheOptions.NeverExpires,
        );
        // Return a new copy because we know that consumers will likely mess with Urls and such.
        // Since it's cached, we don't want one consumer to change something after another has
        // set the values of the properties to their own expectations.
        let copy = PublicAppSettings.create(settings);
        copy.distFolder = settings.distFolder;
        return copy;
    }

    getLayoutInformation(forceRefresh: boolean = false): Promise<LayoutInformation> {
        if (forceRefresh) this.dataCache.delete(AppSettingsService.getLayoutInformationCacheKey);

        return this.dataCache.get(
            AppSettingsService.getLayoutInformationCacheKey,
            () => {
                return this.api.getJson(new GetJsonRequest(Endpoints.Api.Global.GET_LAYOUT_INFORMATION)).then((u) => {
                    let layoutInformation = LayoutInformation.create(u.value);
                    return layoutInformation;
                });
            },
            DataCacheOptions.NeverExpires,
        );
    }

    getClientLayoutInformation(clientId: string): Promise<LayoutInformation> {
        return this.dataCache.get(
            'DCACID_' + clientId,
            () => {
                return new Promise<LayoutInformation>((resolve, reject) => {
                    this.api.getJson(new GetJsonRequest(Endpoints.Api.Global.GET_LAYOUT_INFORMATION, 'clientId=' + clientId)).then((u) => {
                        let layoutInformation = LayoutInformation.create(u.value);
                        resolve(layoutInformation);
                    }, reject);
                });
            },
            DataCacheOptions.NeverExpires,
        );
    }

    useLiteWidgets(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.api.getJson(new GetJsonRequest(Endpoints.Api.Global.USE_LITE_WIDGETS)).then((u: ViewModelResponse<boolean>) => {
                resolve(u.value);
            });
        });
    }

    //TODO rework this for billing app?
    hasAccessToControlAssessments(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.api
                .getJson(new GetJsonRequest(Endpoints.Api.Global.HAS_ACCESS_TO_CONTROL_ASSESSMENTS))
                .then((u: ViewModelResponse<any>) => {
                    resolve(u.value);
                }),
                reject;
        });
    }

    fixURL(url: string) {
        if (url && url[url.length - 1] !== '/') return url + '/';
        else return url;
    }

    determineEnv(url: string): string {
        if (url.includes('dev')) {
            return 'DEV';
        } else if (url.includes('qa')) {
            return 'QA';
        } else if (url.includes('local-apis')) {
            return 'LOCAL';
        } else {
            return 'PROD';
        }
    }
}
